import { IconBtn } from '@/Buttons/IconBtn';
import { SecondaryBtn } from '@/Buttons/SecondaryBtn';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { useState } from 'react';

const Card = styled.div`
    perspective: 150rem;
    position: relative;
    filter: drop-shadow(0px 32px 64px rgba(83, 153, 63, 0.2));
    width: 400px;
    max-width: 100%;
    min-height: 400px;
`;

const side = css`
    transition: transform 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 400px;
    backface-visibility: hidden;
    overflow: hidden;
`;

const name = css`
    font-size: 1.5rem;
    line-height: 133%;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
`;

const ToggleBtn = styled(IconBtn)`
    background: ${({ theme }) => theme.gradient};

    width: 56px;
    height: 56px;
    border-radius: 0;

    > span {
        font-size: 1.77rem;
        line-height: 1rem;
        color: #ffffff;
    }
`;

const Front = styled.div<{ backSide: boolean }>`
    ${side};
    background: ${({ theme }) => theme.white};
    transform: ${({ backSide }) => (backSide ? 'rotateY(180deg)' : ' none')};

    > p:first-of-type {
        color: #999999;
        text-align: right;
        padding: 24px;
        padding-bottom: 0;
        margin: 0 0 40px;
        text-transform: capitalize;
    }

    > .gatsby-image-wrapper {
        margin: 0 auto;
        border-radius: 50%;
        opacity: 0.9999; //safari overflow bug fix
    }

    > div:last-of-type {
        margin-top: 32px;
        padding-left: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > h3 {
            ${name};
        }
    }
`;

const Back = styled.div<{ backSide: boolean }>`
    ${side};
    background: ${({ theme }) => theme.gradient};
    padding-top: 72px;
    padding-left: 40px;
    transform: ${({ backSide }) => (backSide ? 'none' : 'rotateY(-180deg)')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    > div:first-of-type {
        > h3 {
            ${name};
            color: ${({ theme }) => theme.white};
        }

        > p {
            color: ${({ theme }) => theme.offWhite};
            margin: 8px 0 0;
            font-weight: 500;
            text-transform: capitalize;
        }
    }

    > p:first-of-type {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.white};
        margin: 0;
        max-width: 230px;
    }

    > div:last-of-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        > a {
            color: ${({ theme }) => theme.white};
            font-weight: 700;

            ::after {
                filter: brightness(0) invert(1);
            }
        }

        > button {
            > span {
                transform: rotate(-135deg);
            }
        }
    }
`;

type FlipCardProps = {
    title: string;
    name: string;
    description: string;
    link: {
        link: string;
        text: string;
    };
    image: React.ReactNode;
};

export const FlipCard = ({ title, name, description, link, image }: FlipCardProps) => {
    const [backSide, setBackSide] = useState(false);

    const toggleSide = () => {
        setBackSide(side => !side);
    };

    return (
        <Card>
            <Front backSide={backSide}>
                <p>{title}</p>
                {image}
                <div>
                    <h3>{name}</h3>
                    <ToggleBtn onClick={toggleSide}>
                        <span>+</span>
                    </ToggleBtn>
                </div>
            </Front>
            <Back backSide={backSide}>
                <div>
                    <h3>{name}</h3>
                    <p>{title}</p>
                </div>
                <p>{description}</p>
                <div>
                    <SecondaryBtn as={Link} to={link.link}>
                        {link.text}
                    </SecondaryBtn>
                    <ToggleBtn onClick={toggleSide}>
                        <span>+</span>
                    </ToggleBtn>
                </div>
            </Back>
        </Card>
    );
};
