import { AboutHero } from '@/AboutPages/AboutHero';
import { Driven } from '@/AboutPages/Driven';
import { Intro } from '@/AboutPages/Intro';
import { Together } from '@/AboutPages/Together';
import { Seo } from '@/layout/Seo';
import { TwoDoctors } from '@/TwoDoctors';
import { Fragment } from 'react';

const Contact: Page = () => {
    return (
        <Fragment>
            <Seo
                title="About Integrate Institute in Burnsville Mn – Integrate Institute"
                description="Learn about Integrate Institute in Burnsville Minnesota"
            />
            <AboutHero>
                <TwoDoctors doctor />
            </AboutHero>
            <Together />
            <Driven />
            <Intro />
        </Fragment>
    );
};

export default Contact;
