import { FlipCard } from '@/FlipCard';
import { h1, px } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const Section = styled.section`
    ${px};
    padding-top: 32px;
    padding-bottom: 64px;

    > h3 {
        ${h1};
        margin: 0 0 64px;
        text-align: center;
        text-transform: capitalize;
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 32px;

        + h3 {
            margin-top: 64px !important;
        }

        @media (min-width: 1024px) {
            gap: 64px;
        }
    }
`;

const doctors = [
    {
        title: 'doctor of chiropractic',
        name: 'alex twenge',
        description:
            'As a sports enthusiast, Dr. Alex has a passion for working with athletes and active people of all ages...',
        link: {
            link: '/about-us/dr-alex-twenge/',
            text: 'read about dr alex',
        },
        image: (
            <StaticImage
                src="../../../assets/images/about-us/alex.jpg"
                layout="fixed"
                width={224}
                height={224}
                alt="alex twenge"
            />
        ),
    },
    {
        title: 'director of rehabilitation',
        name: 'megan twenge',
        description:
            'Megan is the director of rehabilitation here at Integrate Institute and has a passion...',
        link: {
            link: '/about-us/megan-twenge/',
            text: 'read about megan',
        },
        image: (
            <StaticImage
                src="../../../assets/images/about-us/megan.jpg"
                layout="fixed"
                width={224}
                height={224}
                alt="megan twenge"
            />
        ),
    },
    {
        title: 'Doctor of Chiropractic',
        name: 'dr. derek fruetel',
        description:
            'Dr. Derek was born and raised in Litchfield, MN where he was a three sport athlete...',
        link: {
            link: '/about-us/dr-derek-fruetel/',
            text: 'read about derek',
        },
        image: (
            <StaticImage
                src="../../../assets/images/about-us/derek.jpg"
                layout="fixed"
                width={224}
                height={224}
                alt="dr. derek fruetel"
            />
        ),
    },
    {
        title: 'Doctor of Physcial Therapy',
        name: 'dr. Gavin manning',
        description:
            'Dr. Gavin was born and raised in Litchfield, MN. He played multiple sports throughout high school...',
        link: {
            link: '/about-us/dr-gavin-manning/',
            text: 'read about gavin',
        },
        image: (
            <StaticImage
                src="../../../assets/images/about-us/gavin.jpg"
                layout="fixed"
                width={224}
                height={224}
                alt="dr. gavin manning"
            />
        ),
    },
];

const therapists = [
    {
        title: 'massage therapist',
        name: 'jill fisher',
        description: 'Jill Fisher has been a massage therapist for the last five years...',
        link: {
            link: '/services/massage-therapy/',
            text: 'read about jill',
        },
        image: (
            <StaticImage
                src="../../../assets/images/about-us/jill.jpg"
                layout="fixed"
                width={224}
                height={224}
                alt="jill fisher"
            />
        ),
    },
];

const nutritionist = [
    {
        title: 'Strength and Conditioning Specialist',
        name: 'jake morphis',
        description:
            'As a trainer and coach, Jake is passionate about helping others reach their fitness goals..',
        link: {
            link: '/services/personal-training/',
            text: 'read about jake',
        },
        image: (
            <StaticImage
                src="../../../assets/images/about-us/jake.jpg"
                layout="fixed"
                width={224}
                height={224}
                alt="jake morphis"
            />
        ),
    },
];

export const Intro = () => {
    return (
        <Section>
            <h3>our doctors</h3>
            <div>
                {doctors.map((doctor, i) => (
                    <FlipCard {...doctor} key={i} />
                ))}
            </div>
            <h3>our massage therapist</h3>
            <div>
                {therapists.map((therapist, i) => (
                    <FlipCard {...therapist} key={i} />
                ))}
            </div>

            <h3>Nutrition Coach</h3>
            <div>
                {nutritionist.map((person, i) => (
                    <FlipCard {...person} key={i} />
                ))}
            </div>
        </Section>
    );
};
