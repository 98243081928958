import { Article } from '@/Article';
import { customScrollbar, px } from '@/layout/GlobalStyles';
import check from '@a/check-circle.svg';
import styled from '@emotion/styled';

const Cards = styled.div`
    ${customScrollbar};
    ${px};

    @media (min-width: 1024px) {
        justify-content: center;
        padding: 0;
    }
`;

const Card = styled.div`
    width: 275px;
    flex-shrink: 0;
    padding: 16px;
    border: 1px solid rgba(83, 153, 63, 0.2);
    border-radius: 8px;
    background: none;
    margin-bottom: 32px;
    transition: all 0.15s ease-in-out;
    transition-property: background, border, box-shadow;

    :hover {
        box-shadow: ${({ theme }) => theme.boxShadow2};
        border: 1px solid transparent;
        background: ${({ theme }) => theme.white};
    }

    :nth-of-type(2) {
        margin: 0 16px;
    }

    > h3 {
        margin: 24px 0 16px;
        text-transform: capitalize;
        font-weight: 700;
        font-family: 'Raleway', sans-serif;
    }

    > p {
        margin: 0;
    }

    @media (min-width: 1024px) {
        :nth-of-type(2) {
            margin: 0 32px;
        }
    }
`;

const values = [
    {
        title: 'patient-centric',
        text: 'We promise to put you first, recommend effective treatments, and treat you like family',
    },
    {
        title: 'committed',
        text: 'We’re agile in our approach, and will modify treatments as we unwind the complexity of the injury',
    },
    {
        title: 'Uniquely Skilled',
        text: 'With expertise in both chiropractic and therapy, we’re uniquely suited to solve your injury',
    },
];

export const Together = () => {
    return (
        <section>
            <Article>
                <h1>Better Together</h1>
                <p>
                    Dr. Alex and Megan are thrilled to introduce themselves! Yes, they are married
                    and now business partners who met in college. As former division one athletes,
                    these two have experienced a magnitude of injuries. Creating a clinic that does
                    it all without sacrificing quality was their main goal. There is a reason they
                    provide chiropractic care AND therapy; they believe and have found that results
                    are significantly better with both, including a practice member who is motivated
                    to get better. Go team! Dr. Alex and Megan truly love what they do and the
                    people they get to work with. Mentored by some of the best movement specialists
                    in the world, they have learned from the best and wish to share it with you.{' '}
                </p>
            </Article>
            <Cards>
                {values.map((value, i) => (
                    <Card key={i}>
                        <img src={check} alt="checkmark" width={64} height={64} />
                        <h3>{value.title}</h3>
                        <p>{value.text}</p>
                    </Card>
                ))}
            </Cards>
        </section>
    );
};
