import { h1 } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const Section = styled.section`
    background: ${({ theme }) => theme.gradient};
    padding: 16px;
    max-width: 1200px;
    margin: 32px auto;

    > .gatsby-image-wrapper:first-of-type {
        border-top-left-radius: 128px;
        border-bottom-right-radius: 128px;
    }

    @media (min-width: 768px) {
        padding: 32px;
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        margin: 64px auto;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media (min-width: 1200px) {
        padding: 64px;
        border-radius: 32px;
    }
`;

const TextBox = styled.div`
    > h2 {
        ${h1};
        margin: 32px 0;
        text-transform: capitalize;
    }

    > * {
        color: ${({ theme }) => theme.white};
    }

    @media (min-width: 1024px) {
        max-width: 550px;
        margin-left: ${({ theme }) => theme.spacing.xxl};

        > h2 {
            margin-top: 0;
        }
    }
`;

export const Driven = () => {
    return (
        <Section>
            <StaticImage
                src="../../../assets/images/homepage/different.jpg"
                alt="integrate institute"
            />
            <TextBox>
                <h2>We are driven by values</h2>
                <p>
                    We value people. It is our job to give you honest, ethical, evidence-based
                    answers. Using our team-based approach, we will help you reach your goals via
                    highly individualized, detail oriented, quality care!
                </p>
            </TextBox>
        </Section>
    );
};
